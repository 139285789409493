<template>
  <div class="d-flex-center flex-wrap gap-1">
    <span
      v-for="(item, index) of dobAppliedInfo"
      :key="index"
      class="p-25 border-danger rounded font-small-4"
    >
      <span class="text-danger fw-700 text-nowrap cursor-pointer">{{ item.bookingCode }} - {{ item.ticketNumber }}</span>
      <feather-icon
        v-if="!disabledCopy"
        class="cursor-pointer text-info ml-25"
        icon="CopyIcon"
        @click="!disabledCopy && handleCopyText(item.ticketNumber)"
      />
      <b-spinner
        v-else
        class="ml-25"
        variant="info"
        small
      />
    </span>
  </div>
</template>

<script>
import { BSpinner } from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { useClipboard } from '@vueuse/core'

export default {
  components: {
    BSpinner,
  },
  props: {
    dobAppliedInfo: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const { copy } = useClipboard()
    const disabledCopy = ref(false)

    function handleCopyText(ticket) {
      disabledCopy.value = true
      setTimeout(() => {
        disabledCopy.value = false
      }, 1000)

      copy(ticket)
        .then(() => {
          this.$root.toastSuccess('reservation.sms.copySuccess')
        })
        .catch(() => {
          this.$root.toastError('reservation.sms.copyFail')
        })
    }

    return {
      disabledCopy,
      handleCopyText,
    }
  },
}
</script>
