var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex-center flex-wrap gap-1"
  }, _vm._l(_vm.dobAppliedInfo, function (item, index) {
    return _c('span', {
      key: index,
      staticClass: "p-25 border-danger rounded font-small-4"
    }, [_c('span', {
      staticClass: "text-danger fw-700 text-nowrap cursor-pointer"
    }, [_vm._v(_vm._s(item.bookingCode) + " - " + _vm._s(item.ticketNumber))]), !_vm.disabledCopy ? _c('feather-icon', {
      staticClass: "cursor-pointer text-info ml-25",
      attrs: {
        "icon": "CopyIcon"
      },
      on: {
        "click": function click($event) {
          !_vm.disabledCopy && _vm.handleCopyText(item.ticketNumber);
        }
      }
    }) : _c('b-spinner', {
      staticClass: "ml-25",
      attrs: {
        "variant": "info",
        "small": ""
      }
    })], 1);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }